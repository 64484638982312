<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    top
    min-width="20rem"
    max-width="20rem"
    transition="scale-transition"
    light
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="$attrs"
        v-on="on"
        :value="formattedDatetime"
        readonly
      />
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab v-for="tab in tabs" :key="tab.key" :disabled="tab.disabled">
            <v-icon v-text="tab.icon" />
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item v-for="tab in tabs" :key="tab.key">
            <component
              v-bind:is="tab.component"
              v-model="form[tab.model]"
              @input="tab.cb"
              full-width
              format="24hr"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click.native="clearHandler"
          v-text="clearText"
        />

        <v-btn
          color="primary darken-1"
          text
          @click="okHandler"
          v-text="okText"
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
/**
 * Vuetify datetime picker.
 * Based on: https://github.com/darrenfang/vuetify-datetime-picker
 */

import moment from 'moment'

const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_TIME_FORMAT = 'HH:mm'
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'

export default {
  name: 'pibot-datetime-picker',
  data: () => ({
    menu: false,
    form: {
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    },
    activeTab: null
  }),
  props: {
    datetime: {
      type: [Number],
      default: null
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT
    },
    timeFormat: {
      type: String,
      default: DEFAULT_TIME_FORMAT
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    tabs () {
      return [
        {
          key: 'calendar',
          icon: 'event',
          component: 'v-date-picker',
          props: this.datePickerProps,
          cb: this.showTimePicker,
          model: 'date'
        },
        {
          key: 'timer',
          icon: 'access_time',
          component: 'v-time-picker',
          props: this.timePickerProps,
          cb: () => {},
          model: 'time',
          disabled: !!this.dateSelected
        }
      ]
    },
    dateTimeFormat () {
      return `${this.dateFormat} ${this.timeFormat}`
    },
    defaultDateTimeFormat () {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
    },
    formattedDatetime () {
      return this.selectedDatetime ? moment(this.selectedDatetime).format(this.dateTimeFormat) : ''
    },
    selectedDatetime () {
      if (this.form.date && this.form.time) {
        let datetimeString = `${this.form.date} ${this.form.time}`

        if (this.form.time.length === 5) datetimeString += ':00'

        return moment(datetimeString, this.defaultDateTimeFormat)
      } else return 0
    },
    dateSelected () {
      return !this.form.date
    }
  },
  methods: {
    init () {
      if (!this.datetime) return

      const datetimeMoment = moment(this.datetime)
      this.form.date = datetimeMoment.format(DEFAULT_DATE_FORMAT)
      this.form.time = datetimeMoment.format(DEFAULT_TIME_FORMAT)
    },
    okHandler () {
      this.$emit('change', moment(this.selectedDatetime).valueOf())
      this.resetPicker()
    },
    clearHandler () {
      this.resetPicker()
      this.form.date = DEFAULT_DATE
      this.form.time = DEFAULT_TIME
      this.$emit('change', 0)
    },
    resetPicker () {
      this.menu = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker () {
      this.activeTab = 1
    }
  },
  watch: {
    datetime: function () {
      this.init()
    }
  }
}
</script>
